const { DrugRegimen } = require("../dataValidations")

function FormatCreateHierarchiesData (csvFileAsArrays) {
  const fileRows = [...csvFileAsArrays]
  const addHierarchyRequestList = []

  // Assuming the column name for 'hasMerm'
  const hasMermColumnName = "hasMerm"
  const hasVotColumnName = "hasVot"
  const has99DotsColumnName = "has99Dots"
  const has99DotsLiteColumnName = "has99DotsLite"
  const hasNoneColumnName = "hasNone"
  const drugRegimenColumnName = "drugRegimen"

  // Find the index of the 'hasMerm' column in the header row
  const hasMermColumnIndex = fileRows[0].indexOf(hasMermColumnName)
  const hasVotColumnIndex = fileRows[0].indexOf(hasVotColumnName)
  const has99DotsColumnIndex = fileRows[0].indexOf(has99DotsColumnName)
  const has99DotsLiteColumnIndex = fileRows[0].indexOf(has99DotsLiteColumnName)
  const hasNoneColumnIndex = fileRows[0].indexOf(hasNoneColumnName)
  const drugRegimenColumnIndex = fileRows[0].indexOf(drugRegimenColumnName)

  fileRows.slice(1).forEach((row, rowIndex) => {
    const dataObj = {}

    fileRows[0].forEach((key, index) => {
      let value = row[index]

      if (value === "TRUE") {
        value = true
      } else if (value === "FALSE") {
        value = false
      }
      dataObj[key] = value
    })

    const addHierarchyRequestItem = {
      hierarchy: {
        name: dataObj.name,
        code: dataObj.code,
        level: dataObj.level,
        type: dataObj.type,
        parentName: dataObj.parentName,
        hasChildren: dataObj.hasChildren,
        mergeStatus: dataObj.mergeStatus,
        longitude: dataObj.longitude,
        latitude: dataObj.latitude,
        parentCode: dataObj.parentCode,
        parentType: dataObj.parentType
      },
      associations: {
        [drugRegimenColumnName]: row[drugRegimenColumnIndex]
      },
      configs: {
        [hasMermColumnName]: row[hasMermColumnIndex] === "TRUE" || row[hasMermColumnIndex] === true,
        [hasVotColumnName]: row[hasVotColumnIndex] === "TRUE" || row[hasVotColumnIndex] === true,
        [has99DotsColumnName]: row[has99DotsColumnIndex] === "TRUE" || row[has99DotsColumnIndex] === true,
        [has99DotsLiteColumnName]: row[has99DotsLiteColumnIndex] === "TRUE" || row[has99DotsLiteColumnIndex] === true,
        [hasNoneColumnName]: row[hasNoneColumnIndex] === "TRUE" || row[hasNoneColumnIndex] === true
      }
    }

    addHierarchyRequestList.push(addHierarchyRequestItem)
  })

  const addHierarchyBulkRequest = {
    addHierarchyRequestList
  }

  return addHierarchyBulkRequest
}

module.exports = FormatCreateHierarchiesData
