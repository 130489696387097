import { TrueorFalse, DrugRegimen } from '../dataValidations'
import { TypeOfPatientsAddedHUB, HubAccessTypes } from '../utils'
export const HUBCreateHierarchiesValidations = (rows) => {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^parentName$/.test(row[0])){
    return "Column 1 heading should be parentName"
  } else if (!row[1] || !/^code$/.test(row[1])){
    return "Column 2 heading should be code"
  } else if (!row[2] || !/^type$/.test(row[2])){
    return "Column 3 heading should be type"
  } else if (!row[3] || !/^name$/.test(row[3])){
    return "Column 4 heading should be name"
  } else if (!row[4] || !/^level$/.test(row[4])){
    return "Column 5 heading should be level"
  } else if (!row[5] || !/^hasMerm$/.test(row[5])){
    return "Column 6 heading should be hasMerm"
  } else if (!row[6] || !/^sector$/.test(row[6])){
    return "Column 7 heading should be sector"
  } else if (!row[7] || !/^hasChildren$/.test(row[7])){
    return "Column 8 heading should be hasChildren"
  } else if (!row[8] || !/^drugRegimen$/.test(row[8])){
    return "Column 9 heading should be drugRegimen"
  } else if (!row[9] || !/^hasVot$/.test(row[9])){
    return "Column 10 heading should be hasVot"
  } else if (!row[10] || !/^hasNone$/.test(row[10])){
    return "Column 11 heading should be hasNone"
  } else if (!row[11] || !/^has99Dots$/.test(row[11])){
    return "Column 12 heading should be has99Dots"
  } else if (!row[12] || !/^accessType$/.test(row[12])){
    return "Column 13 heading should be accessType"
  } else if (!row[13] || !/^typeOfPatient$/.test(row[13])){
    return "Column 14 heading should be typeOfPatient"
  } else if (!row[14] || !/^parentCode$/.test(row[14])){
    return "Column 15 heading should be parentCode"
  } else if (!row[15] || !/^parentType$/.test(row[15])){
    return "Column 16 heading should be parentType"
  }
}
function validateCsvRow (row) {
  if (!row[1] || row[1].length > 10){
    return "Code length should be less than or equal to 10 and capital letters only"
  } else if (!row[4] || !/^[1-5]$/.test(row[4])){
    return "Column 5 should have a valid level"
  } else if (!row[5] || !TrueorFalse(row[5])){
    return "Column 6 should have a valid hasMerm"
  } else if (!row[7] || !TrueorFalse(row[7])){
    return "Column 8 should have a valid hasChildren"
  } else if (!row[8] || !DrugRegimen(row[8])){
    return "Column 9 should have a valid drugRegimen"
  } else if (!row[9] || !TrueorFalse(row[9])){
    return "Column 10 should have a valid hasVot"
  } else if (!row[10] || !TrueorFalse(row[10])){
    return "Column 11 should have a valid hasNone"
  } else if (!row[11] || !TrueorFalse(row[11])){
    return "Column 12 should have a valid has99Dots"
  }
}
