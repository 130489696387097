<template>
  <LoginForm :Heading = "getHeading()" @submit="submit"/>
</template>

<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { initializePlatformMatomo, DataForMatomoTracking, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, apiResponseValue } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
import { HUBCreateHierarchiesValidations } from './../../utils/HUBValidations/HUBCreateHierarchiesValidations'
const FormatCreateHierarchiesData = require("./../../utils/HUBValidations/FormatCreateHierarchiesData")
const csv = require("papaparse")

export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd',
      'platform',
      'emailId'
    ])
  },
  mounted: function () {
    initializePlatformMatomo()
  },
  beforeUpdate: function () {
    if (this.platform !== this.$route.query.platform) {
      this.clearMessage()
    }
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'clearMessage',
      'success'
    ]),
    getHeading: function (){
      return this.$route.query.platform + ' Create Hierarchies'
    },
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = HUBCreateHierarchiesValidations(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = FormatCreateHierarchiesData(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/v1/admin/hierarchy/bulk?userEmail=' + this.emailId, 'registryENV'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data.success === true){
                this.setErrorMessage({ isError: false, errMsg: '' })
                this.success({ isOk: true, succMsg: 'Hierarchies Creation Process Started, Confirmation will be sent to your email.' })
              } else if (jsonUploadResponse.data.error != null){
                this.setErrorMessage({ isError: true, errMsg: jsonUploadResponse.data.error })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }
              adminLogData(this.getHeading(), 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
